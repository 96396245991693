import { ScheduleExportParams } from 'API/Schedule/useScheduleExport/types'
import qs from 'qs'

import Utils from 'services/Utils'

export function scheduleExportParamsToQueryString(
  filter: ScheduleExportParams['filter'],
) {
  return qs.stringify(
    {
      filter: {
        start_on: Utils.JsonApi.betweenFilter(filter.period, {
          includeEndDate: false,
        }),
        ...(filter?.areas && Utils.JsonApi.areasFilter(filter.areas)),
        ...((!filter.published || !filter.unpublished) && {
          state: filter.unpublished ? 'unpublished' : 'published',
        }),
      },
    },
    { skipNulls: true, arrayFormat: 'brackets' },
  )
}
