import { useFPSWorkersCouncilExport } from './useFPSWorkersCouncilExport'
import { useScheduleExport } from './useScheduleExport'
import { useSchedulesByCursor } from './useSchedulesByCursor'
import { useSchedulesCount } from './useSchedulesCount'
import { useSchedulesGardaListView } from './useSchedulesGardaListView'
import { useShiftsWeeklySchedulesByCursor } from './useShiftsWeeklySchedulesByCursor'

export const ScheduleAPI = {
  byCursor: useSchedulesByCursor,
  byCursorForShiftsWeekly: useShiftsWeeklySchedulesByCursor,
  byCursorForGardaDaily: useSchedulesGardaListView,
  schedulesCount: useSchedulesCount,
  scheduleExport: useScheduleExport,
  fpsWorkersCouncilExport: useFPSWorkersCouncilExport,
}
