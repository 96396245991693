import { AccessLevel } from 'constants/ids'

import { administration, everyone, management, noone } from '../config'
import { AbilityTree } from '../types'

export const deluxeTree: AbilityTree = {
  // User info aside
  userInfoAside: {
    isOwnProfile: {
      view: everyone,
    },
    isOtherProfile: { view: management },
  },
  changeAvatar: {
    isOwnProfile: { doAction: administration },
    isOtherProfile: {
      doAction: management,
    },
  },
  changePassword: {
    isOwnProfile: { doAction: administration },
    isOtherProfile: {
      doAction: management,
    },
  },
  archive: {
    isOwnProfile: { doAction: administration },
    isOtherProfile: {
      doAction: management,
    },
  },
  removeFromLocation: {
    isOwnProfile: { doAction: administration },
    isOtherProfile: {
      doAction: administration,
    },
  },
  changeFirstLastName: {
    isOwnProfile: { doAction: administration },
    isOtherProfile: {
      doAction: management,
    },
  },
  changeEmail: {
    isOwnProfile: { doAction: noone },
    isOtherProfile: {
      doAction: administration,
    },
  },
  faceId: {
    isOwnProfile: { view: administration, update: administration },
    isOtherProfile: { view: management, update: management },
  },
  changeAccessLevel: {
    isOwnProfile: { doAction: administration },
    isOtherProfile: {
      doAction: management,
    },
  },
  viewPin: {
    isOwnProfile: {
      doAction: everyone,
    },
    isOtherProfile: {
      doAction: management,
    },
  },
  profileInfo: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: management,
    },
  },
  gender: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: management,
    },
  },
  supervisors: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: administration,
    },
  },
  timesheetCode: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: management,
    },
  },
  dateOfHiring: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: management,
    },
  },
  dateOfBirth: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: management,
    },
  },
  address: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: management,
    },
  },
  username: {
    isOwnProfile: { view: noone },
    isOtherProfile: { view: noone },
  },

  // General
  roles: {
    isOwnProfile: {
      view: everyone,
      create: administration,
      update: administration,
      remove: administration,
    },
    isOtherProfile: {
      view: management,
      update: administration,
      remove: administration,
      create: management,
    },
  },
  additionalInformation: {
    isOwnProfile: {
      view: everyone,
    },
    isOtherProfile: {
      view: management,
    },
  },
  roleTags: {
    isOwnProfile: {
      view: everyone,
      update: administration,
    },
    isOtherProfile: {
      view: management,
      update: management,
      remove: management,
      create: management,
    },
  },
  statistics: {
    isOwnProfile: { view: administration },
    isOtherProfile: {
      view: management,
      update: management,
      remove: management,
      create: management,
    },
  },
  roleEffectiveDates: {
    isOwnProfile: {
      view: everyone,
      create: administration,
      update: administration,
      remove: administration,
    },
    isOtherProfile: {
      view: management,
      update: administration,
      remove: administration,
      create: management,
    },
  },

  // Locations tab
  locationsList: {
    isOwnProfile: {
      view: everyone,
    },
    isOtherProfile: {
      view: management,
      update: management,
      remove: management,
      create: management,
    },
  },
  relocationRequest: {
    isOwnProfile: { doAction: administration },
    isOtherProfile: { doAction: management },
  },
  availabilitySchedule: {
    isOwnProfile: { view: everyone },
    isOtherProfile: {
      view: management,
      update: management,
      remove: management,
      create: management,
    },
  },
  logs: {
    isOwnProfile: { view: administration },
    isOtherProfile: {
      view: management,
    },
  }, // People
  // Emergency
  health_file: {
    isOwnProfile: {
      view: administration,
      update: administration,
    },
    isOtherProfile: {
      view: noone,
    },
  },
  emergency_contact: {
    isOwnProfile: {
      view: administration,
      update: administration,
    },
    isOtherProfile: {
      view: noone,
    },
  },
  // Administrative
  disciplinary_table: {
    isOwnProfile: {
      update: administration,
      create: administration,
      remove: administration,
    },
    isOtherProfile: {
      view: noone,
    },
  },
  disciplinary_notes: {
    isOwnProfile: { view: administration, update: administration },
    isOtherProfile: {
      view: noone,
    },
  },

  // Salary Revision
  salary_table: {
    isOwnProfile: { view: administration },
    isOtherProfile: {
      view: noone,
    },
  },
  annual_performance_table: {
    isOwnProfile: { view: administration },
    isOtherProfile: {
      view: noone,
    },
  },
  work_agreement_table: {
    isOwnProfile: { view: administration },
    isOtherProfile: {
      view: noone,
    },
  },
  deluxeSync: {
    isOwnProfile: { view: management },
    isOtherProfile: { view: management },
  },

  employeeAvailabilityRequests: {
    isOwnProfile: { view: everyone },
    isOtherProfile: { view: management },
  },

  // Qualifications
  fpsQualifications: {
    isOwnProfile: { view: noone },
    isOtherProfile: { view: noone },
  },

  // Payroll
  accessPayrollTab: {
    doAction: administration,
  },
  // CNESST Disabled for now
  // [ALL_PROFILE_FEATURES.CNESSTTable]: {
  //   [CONDITIONS.isOwnProfile]: {},
  //   [CONDITIONS.isOthersProfile]: {
  //     [view]: administration,
  //     [edit]: administration,
  //   },
  // },
  groups: {
    isOwnProfile: { view: administration },
    isOtherProfile: {
      view: management,
    },
  },

  accessPeopleTab: {
    doAction: management,
  },
  exportPeople: {
    doAction: management,
  },
  invite_people: {
    doAction: management,
  },
  changeTemporaryAccessLevel: {
    doAction: management,
  },

  // Timeoff
  timeoff_table: {
    isOwnProfile: {
      view: everyone,
      create: everyone,
    },
    isOtherProfile: {
      view: management,
      create: management,
    },
  },
  accessTimeOffTab: {
    doAction: management,
  },
  requestTimeOff: {
    doAction: everyone,
  },
  assignTimeOff: {
    doAction: management,
  },
  accessTimeoffNotifications: {
    doAction: [...management, AccessLevel.Employee],
  },
  // Schedule
  managementSchedules: {
    withAssignedEmployee: {
      assign: management,
      update: management,
      remove: management,
      create: management,
      copy: management,
    },
    withoutAssignedEmployee: {
      assign: management,
      update: management,
      remove: management,
      create: management,
      copy: management,
    },
  },
  accessScheduleTab: {
    doAction: [AccessLevel.Employee, ...management],
  },
  autoAssign: {
    doAction: management,
  },
  publishSchedules: {
    doAction: everyone,
  },

  // Timecards
  accessTimecardSummary: {
    doAction: management,
  },
  // Weekly timesheets
  deleteWeeklyTimesheet: {
    doAction: noone,
  },
  // Builder
  accessReportBuilderTab: {
    doAction: management,
  },
  // Metrics
  currentStateSection: {
    doAction: management,
  },
  deviationsSection: {
    doAction: management,
  },
  unfilledPostitionsWidget: {
    doAction: management,
  },
  hoursChartWidget: {
    doAction: management,
  },

  // Integrations
  accessIntegrationEventsTab: {
    view: administration,
  },

  // Custom Fields
  accessCustomFieldValuesTab: {
    doAction: management,
  },

  // Settings
  accessToken: {
    doAction: management,
  },

  // Time buckets
  selectTimeBuckets: {
    view: everyone,
    update: everyone,
  },
  accessTimeBucketName: {
    doAction: everyone,
  },
}
