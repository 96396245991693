import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'
import { useProfileQuery } from 'GraphQL/User/Queries/'

import compact from 'lodash/compact'

import { ChangeEmployeeEmailMutation } from './GraphQL/Mutations'

export function useChangeEmployeeEmail() {
  const [innerCreate, { loading: updating }] = useApolloMutation<
    MutationData<'changeEmployeeEmail'>,
    Gateway.MutationChangeEmployeeEmailArgs
  >(ChangeEmployeeEmailMutation, {
    translationKey: 'employees.changeEmail.toast',
    refetchQueries: compact([getOperationName(useProfileQuery)]),
  })

  const updateEmail = async (input: Gateway.ChangeEmployeeEmailInput) => {
    const response = await innerCreate({
      variables: {
        input,
      },
    })

    return !response.errors
  }

  return {
    updateEmail,
    updating,
  }
}
